
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FAFEFF;
  font-size: 14px;
}

.mySelect .MuiInputLabel-outlined {
  transform: translate(12px, 8px) scale(1) !important;
}

.mySelect .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
}

.filterBox .mySelect .MuiInputLabel-outlined {
  transform: translate(12px, 8px) scale(1) !important;
}

.filterBox .mySelect .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
}


.logo
{
  /* background-image: url(assets/images/LOGO.svg);  
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  padding-bottom: 40px; */
}

.toolbarLogo
{
  display: inline-block;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.test1
{
  background-color: red;
}

.test2
{ 
  background-color: green;
}

.test3
{
  background-color: yellow;
}

.test4
{
  background-color: blue;
}

.loginForm .MuiTextField-root {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.loginForm .MuiFormControl-root
{
  margin-bottom: 20px;
}

.loginForm .MuiFormHelperText-root
{
  position: absolute;
  bottom: 0px;
}

.loginForm .active
{
  color:green;
}

.loginForm .disabled
{
  color:red;
}

.headerAdmin
{
  display:flex !important;
  background-color: #fff !important;
  min-height: 64px!important;
}

.headerAdmin .logo
{
  display: inline-flex;
  max-width: 150px;
  margin-left: 0px;
}

.headerAdmin .userInfo
{
  color: #051324;
  margin-left: auto;
  font-size: 14px;
}

.MuiDrawer-paper
{
  background: #051324 !important;
  color: #fff !important;
  font-size: 13px;
}

.MuiDrawer-paper .MuiDivider-root
{
  border-color: rgba(255, 255, 255, 0.5);
}

.addButton
{
  background: rgb(255, 0, 42);
  text-transform: uppercase;
  padding:5px 12px;
  text-decoration: none !important;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  line-height: 1;
  align-content: center;
  font-size: 12px;
  font-weight: 600 !important;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.addButton::before
{
  content: "+";
  margin-right: 5px;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
}

.breadcrumbs, .breadcrumbs li p
{
  font-size: 12px !important;
}

.navbarLeft ul
{
  padding: 0;
  font-size: 13px;
  margin: 0 5px 0 0;
  cursor: default;
}

.navbarLeft ul li
{
  display: flex;
  align-items: center;
  align-content: center;
}

.navbarLeft ul li
{
  margin: 0 !important;
  list-style: none;
  transition: all ease 0.5s;
  padding:10px 5px 10px 22px !important;
  font-weight: 600;
}


.navbarLeft a
{
  text-decoration: none;
  color: #fff;
}

.navbarLeft ul ul li a
{
  color: rgba(255, 255,255, 0.75);
}

.navbarLeft ul ul li
{
  padding-left: 7px !important;
  margin-left: 30px !important;
  transition: all ease 0.5s;
  font-weight: 400;
}

.navbarLeft ul ul li.active
{
  background: rgba(0, 114, 229, 0.15);
  border-radius: 5px;
}

.n  avbarLeft ul ul li.active a
{
  color: #0072E5 !important
}

.navbarLeft ul ul li:hover
{
  background: rgba(255,255,255, 0.15) !important;
  border-radius: 5px;
}

.navbarLeft ul ul
{
  border-left: rgba(0, 114, 229, 0.85) solid 1px;
  margin-left: 30px;
}

.editBtn
{
  background: #ff002a !important;
  color: #fff !important;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.detailBtn
{
  font-weight: 600 !important;
  background-color: rgba(0, 114, 229, 0.85);
  color: #fff !important;
  text-decoration: none !important;
  padding: 7px 15px;
  border-radius: 15px;
  margin: 0 15px !important;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.detailBtn:hover {
  background-color: rgba(0, 114, 229, 1);

}

.MuiInputBase-input
{
  font-size: 13px !important;
}

.loginForm .MuiOutlinedInput-notchedOutline
{
  border: 0px !important;
  border-bottom: solid 3px rgba(0, 114, 229, 1) !important;
}

.loginForm .iconInput
{
   top: 17px !important;
}

.loginForm .MuiInputBase-input
{
  font-size: 13px !important;
  height: 40px;
}

.iconInput
{
  position: absolute;
  right:10px;
  top: 8px;
}

.tabs
{
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}

.tabs button
{
  font-weight: 700 !important;
}

.boxTable
{
  width:calc(100% - 1px);
  display: flex;
  box-sizing: border-box;
}

.boxTable .MuiDataGrid-root
{
  width: 1px!important;
}

.boxUploader
{
  width: 100%;
  display: flex;
  border: dashed 2px #D2D2D2;
  align-items: center;
  justify-content: center;
}

.boxUploader .drag
{
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
  text-align: center;
  color: #051324;
}

.boxUploader .drag .alto
{
  color: #d2d2d2;
}

.boxUploader .drag .btnUp
{
  background: #E8FAFF;
  border-radius: 20px;
  font-family: 16px !important;
  padding: 10px 25px;
  display: inline-block;
  font-weight: 500;
}

.boxUploader .drag .icon
{
  color: #D2D2D2;
  width: 50px;
  height: 50px;
}

.shadowBox {
  box-shadow: 0px 10px 25px 2px rgba(0, 0, 0, .2);

}